<template>
    <div class="projectWrap">
        <div class="projectTop">
            <div class="projectTitle">
                <span>项目信息</span>
                <a-select v-model="currentProject" placeholder="点击切换项目" style="width: 200px; margin-left: 40px">
                    <a-select-option value="name">
                        项目1
                    </a-select-option>
                    <a-select-option value="holder">
                        项目2
                    </a-select-option>
                    <a-select-option value="type">
                        项目3
                    </a-select-option>
                </a-select>
            </div>
            <a-button type="primary" @click="gotoHome">
                返回主页
            </a-button>
        </div>
        <div class="projectInfo">
            <ul>
                <li>项目名称</li>
                <li>描述</li>
                <li>位置信息</li>
            </ul>
            <ul>
                <li>XXXXX</li>
                <li>XXXXXXXX</li>
                <li>重庆市渝北区金开大道</li>
            </ul>
            <ul>
                <li>项目id</li>
                <li>创建时间</li>
            </ul>
            <ul>
                <li>202102010022</li>
                <li>2021年7月13日14:17:28</li>
            </ul>
        </div>
        <div class="projectEcharts">
            <div class="echartsLine">
                <div ref="chart" style="width:500px;height:300px" />
                <div ref="chart1" style="width:500px;height:300px" />
                <ul class="echartsDesc">
                    <li style="margin-right: 40px">
                        <span>设备总数</span>
                        <span style="margin-left: 20px">1110</span>
                    </li>
                    <li>
                        <span>未激活数</span>
                        <span style="margin-left: 20px">110</span>
                    </li>
                </ul>
                <ul class="echartsDesc" style="right: 0">
                    <li style="margin-right: 40px">
                        <span>当月消息总量</span>
                        <span style="margin-left: 20px">111110</span>
                    </li>
                </ul>
            </div>
            <div class="echartsPie">
                <ul class="productNum">
                    <li>产品总数（个）</li>
                    <LI>2</LI>
                </ul>
                <div class="pieList">
                    <div ref="chart2" style="width:300px;height:300px" />
                    <div ref="chart3" style="width:300px;height:300px" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const echarts = require('echarts')
const option = {
    backgroundColor: '#fff',
    title: {
        text: '当前在线 123'
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        }
    },
    toolbox: {
        show: true,
        orient: 'vertical',
        feature: {
            restore: {show: true}
        }
    },
    xAxis: {
        type: 'category',
        data: ['01/01', '01/02', '01/03', '01/04', '01/05', '01/06', '01/07', '01/08', '01/09', '01/10']
    },
    yAxis: {
        type: 'value'
    },
    series: [{
        name: '新增设备注册',
        data: [120, 200, 150, 80, 70, 110, 130, 100, 90, 110],
        type: 'bar',
        showBackground: true,
        itemStyle: {
            color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                    {offset: 0, color: '#83bff6'},
                    {offset: 0.5, color: '#188df0'},
                    {offset: 1, color: '#188df0'}
                ]
            )
        }
    }]
}
const option1 = {
    backgroundColor: '#fff',
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        }
    },
    title: {
        text: '今日设备消息量 1123'
    },
    toolbox: {
        show: true,
        orient: 'vertical',
        feature: {
            restore: {show: true}
        }
    },
    xAxis: {
        type: 'category',
        data: ['01/01', '01/02', '01/03', '01/04', '01/05', '01/06', '01/07', '01/08', '01/09', '01/10']
    },
    yAxis: {
        type: 'value'
    },
    series: [{
        name: '消息数',
        data: [20, 100, 150, 180, 170, 10, 30, 100, 90, 110],
        type: 'bar',
        showBackground: true,
        itemStyle: {
            color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                    {offset: 0, color: '#CE9FFC'},
                    {offset: 1, color: '#7367F0'}
                ]
            )
        }
    }]
}
const option2 = {
    title: {
        text: '产品接入协议占比',
        left: 'center'
    },
    tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    legend: {
        top: 'bottom'
    },
    series: [
        {
            name: '产品接入协议占比',
            type: 'pie',
            radius: '50%',
            data: [
                {value: 108, name: 'TCP'},
                {value: 35, name: 'MQTT'},
                {value: 80, name: 'UDP'},
                {value: 44, name: '其他'}
            ],
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
    ]
}
const option3 = {
    title: {
        text: '联网方式占比',
        left: 'center'
    },
    tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    legend: {
        top: 'bottom'
    },
    series: [
        {
            name: '联网方式占比',
            type: 'pie',
            radius: '50%',
            data: [
                {value: 1048, name: '蜂窝'},
                {value: 735, name: '以太'},
                {value: 580, name: 'wifi'},
                {value: 484, name: '组网网关'}
            ],
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
    ]
}
export default {
    name: "Index",
    data() {
        return {
            option,
            option1,
            option2,
            option3,
            // 当前选择的项目
            currentProject: undefined
        }
    },
    mounted () {
        this.initCharts()
    },
    methods: {
        initCharts () {
            let myChart = echarts.init(this.$refs.chart) // 绘制图表
            myChart.setOption(this.option)
            let myChart1 = echarts.init(this.$refs.chart1) // 绘制图表
            myChart1.setOption(this.option1)
            let myChart2 = echarts.init(this.$refs.chart2) // 绘制图表
            myChart2.setOption(this.option2)
            let myChart3 = echarts.init(this.$refs.chart3) // 绘制图表
            myChart3.setOption(this.option3)
        },
        gotoHome() {
            this.$router.push('/home')
        }
    }
}
</script>

<style lang="scss" scoped>
.projectWrap {
    margin: 30px 40px;
    color: #333;
    .projectTop {
        display: flex;
        justify-content: space-between;
        .projectTitle {
            font-size: 20px;
            font-weight: bold;
        }
    }
    .projectInfo {
        display: flex;
        justify-content: space-between;
        margin: 30px 60px;
        ul {
            margin: 0;
            text-align: center;
            li {
                margin-bottom: 20px;
            }
        }
    }
    .projectEcharts {
        background: #eee;
        padding: 40px;
        .echartsLine {
            position: relative;
            display: flex;
            justify-content: space-between;
            .echartsDesc {
                position: absolute;
                width: 500px;
                bottom: 0px;
                display: flex;
                justify-content: center;
            }
        }
        .echartsPie {
            display: flex;
            padding: 20px 60px 0;
            align-items: center;
            background: #fff;
            margin-top: 30px;
            border: 1px solid #333;
            .productNum {
                padding-right: 60px;
                text-align: center;
                margin: 0;
                border-right: 1px solid #ccc;
                li {
                    margin-bottom: 20px;
                }
            }
            .pieList {
                display: flex;
                flex: 1;
                padding-left: 60px;
                justify-content: space-between;
            }
        }
    }
}
</style>
